<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="eventPriceConfigInputPopup"
      header="골프 이벤트 요금 설정"
      width="459"
      allowDragging="true"
      showCloseIcon="true"
      isModal="false"
      :cssClass="
        tsPaymtEvent.eventId
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
      @close="onEventPriceConfigInputPopupClosed"
    >
      <div class="window advancePaymentDepositInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">골프 이벤트 요금 설정</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">제목</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="title"
                                        v-model="tsPaymtEvent.title"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">시작/종료일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group dateRange">
                                    <div class="form">
                                      <input-date-range
                                        ref="inputDateRangeValue"
                                        v-model="inputDateRangeValue"
                                        type="lookup-condition"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">시작/종료시간</div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 119px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="startTime"
                                        v-model="tsPaymtEvent.startTime"
                                        :dataSource="
                                          inputOptions.startTimeOptions
                                        "
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                      />
                                      <!--                                      <ejs-timepicker-->
                                      <!--                                        ref="startTime"-->
                                      <!--                                        v-model="tsPaymtEvent.startTime"-->
                                      <!--                                        format="HH:mm"-->
                                      <!--                                        floatLabelType="never"-->
                                      <!--                                        :strictMode="true"-->
                                      <!--                                        cssClass="body-data-timepicker"-->
                                      <!--                                        @change="onStartTimeChange"-->
                                      <!--                                      />-->
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group text">~</li>
                                  <li
                                    class="item form-group"
                                    style="width: 118px"
                                  >
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="endTime"
                                        v-model="tsPaymtEvent.endTime"
                                        :dataSource="
                                          inputOptions.endTimeOptions
                                        "
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                      />
                                      <!--                                      <ejs-timepicker-->
                                      <!--                                        ref="endTime"-->
                                      <!--                                        v-model="tsPaymtEvent.endTime"-->
                                      <!--                                        format="HH:mm"-->
                                      <!--                                        floatLabelType="never"-->
                                      <!--                                        :strictMode="true"-->
                                      <!--                                        cssClass="body-data-timepicker"-->
                                      <!--                                        @change="onEndTimeChange"-->
                                      <!--                                      />-->
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">반복주기타입</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="reptitCycleType"
                                        v-model="tsPaymtEvent.reptitCycleType"
                                        :dataSource="
                                          inputOptions.reptitCycleTypeOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                        @change="onReptitCycleTypeChanged"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div
                                class="title"
                                :class="
                                  tsPaymtEvent.reptitCycleType === 'WEEKLY'
                                    ? 'required'
                                    : ''
                                "
                              >
                                반복주기요일
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group text"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                      text-align: center;
                                    "
                                  >
                                    전체
                                  </li>
                                  <li
                                    class="item form-group text"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                      text-align: center;
                                    "
                                  >
                                    월
                                  </li>
                                  <li
                                    class="item form-group text"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                      text-align: center;
                                    "
                                  >
                                    화
                                  </li>
                                  <li
                                    class="item form-group text"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                      text-align: center;
                                    "
                                  >
                                    수
                                  </li>
                                  <li
                                    class="item form-group text"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                      text-align: center;
                                    "
                                  >
                                    목
                                  </li>
                                  <li
                                    class="item form-group text"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                      text-align: center;
                                    "
                                  >
                                    금
                                  </li>
                                  <li
                                    class="item form-group text"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                      text-align: center;
                                    "
                                    :style="{
                                      color: commonCodesGetColorValue(
                                        'DW_CODE',
                                        '7'
                                      ),
                                    }"
                                  >
                                    토
                                  </li>
                                  <li
                                    class="item form-group text"
                                    style="
                                      width: 37px;
                                      border-right: 0px solid #e0e0e0;
                                      text-align: center;
                                    "
                                    :style="{
                                      color: commonCodesGetColorValue(
                                        'DW_CODE',
                                        '1'
                                      ),
                                    }"
                                  >
                                    일
                                  </li>
                                </ul>
                                <ul class="row" ref="reptitDwAllArea">
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="tsPaymtEvent.reptitDwAll"
                                              :disabled="
                                                tsPaymtEvent.reptitCycleType !==
                                                'WEEKLY'
                                              "
                                              @change="onReptitDwAllChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="
                                                tsPaymtEvent.reptitDws[1]
                                              "
                                              :disabled="
                                                tsPaymtEvent.reptitCycleType !==
                                                'WEEKLY'
                                              "
                                              @change="onReptitDwChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="
                                                tsPaymtEvent.reptitDws[2]
                                              "
                                              :disabled="
                                                tsPaymtEvent.reptitCycleType !==
                                                'WEEKLY'
                                              "
                                              @change="onReptitDwChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="
                                                tsPaymtEvent.reptitDws[3]
                                              "
                                              :disabled="
                                                tsPaymtEvent.reptitCycleType !==
                                                'WEEKLY'
                                              "
                                              @change="onReptitDwChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="
                                                tsPaymtEvent.reptitDws[4]
                                              "
                                              :disabled="
                                                tsPaymtEvent.reptitCycleType !==
                                                'WEEKLY'
                                              "
                                              @change="onReptitDwChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="
                                                tsPaymtEvent.reptitDws[5]
                                              "
                                              :disabled="
                                                tsPaymtEvent.reptitCycleType !==
                                                'WEEKLY'
                                              "
                                              @change="onReptitDwChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 37px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="
                                                tsPaymtEvent.reptitDws[6]
                                              "
                                              :disabled="
                                                tsPaymtEvent.reptitCycleType !==
                                                'WEEKLY'
                                              "
                                              @change="onReptitDwChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 37px;
                                      border-right: 0px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="
                                                tsPaymtEvent.reptitDws[0]
                                              "
                                              :disabled="
                                                tsPaymtEvent.reptitCycleType !==
                                                'WEEKLY'
                                              "
                                              @change="onReptitDwChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">반복주기종료일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 119px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="form">
                                      <input-date
                                        ref="reptitCycleEndDate"
                                        format="YYYY-MM-DD"
                                        type="lookup-condition"
                                        v-model="
                                          tsPaymtEvent.reptitCycleEndDate
                                        "
                                        :isGetDateInfo="true"
                                        @change="onReptitCycleEndDateChanged"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group text"
                                    style="
                                      width: 35px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    {{ tsPaymtEvent.reptitCycleEndDateDwName }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">회원등급</div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    v-show="!!tsPaymtEvent.eventId"
                                    class="item form-group"
                                    style="border-right: 0px"
                                  >
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="memberGrade"
                                        v-model="
                                                  tsPaymtEvent.memberGrade"
                                        :dataSource="
                                          inputOptions.memberGradeOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    v-show="!tsPaymtEvent.eventId"
                                    class="item form-group"
                                  >
                                    <div class="form">
                                      <ejs-multiselect
                                        ref="memberGrades"
                                        v-model="tsPaymtEvent.memberGrades"
                                        :dataSource="
                                          inputOptions.memberGradeOptions
                                        "
                                        :fields="commonCodeFields"
                                        :showDropDownIcon="true"
                                        cssClass="body-data-multiselect"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">주중요금</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="weekdayPaymtCode"
                                        v-model="tsPaymtEvent.weekdayPaymtCode"
                                        :dataSource="
                                          inputOptions.paymtCodeOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                        @change="onWeekdayPaymtCodeChanged"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">주말요금</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="weekendPaymtCode"
                                        v-model="tsPaymtEvent.weekendPaymtCode"
                                        :dataSource="
                                          inputOptions.paymtCodeOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                        @change="onWeekendPaymtCodeChanged"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        v-model="tsPaymtEvent.remarks"
                                        :maxlength="400"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">등록자/일시</div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group text"
                                    style="width: 80px"
                                  >
                                    {{ tsPaymtEvent.insertName }}
                                  </li>
                                  <li class="item form-group text">
                                    {{ tsPaymtEvent.insertDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">수정자/일시</div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group text"
                                    style="width: 80px"
                                  >
                                    {{ tsPaymtEvent.updateName }}
                                  </li>
                                  <li class="item form-group text">
                                    {{ tsPaymtEvent.updateDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="delete">
              <erp-button
                  button-div="DELETE"
                  :is-shortcut-button="true"
                  :disabled="!tsPaymtEvent.eventId"
                  @click.native="onDeleteButtonClicked"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeEventPriceConfigInputPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputDate from "@/components/common/datetime/InputDate";
import {
  getTodayNavigationDate,
  getDayOfWeekCaption,
  getFormattedDateTimePicker,
} from "@/utils/date";
import {
  commonCodesGetCommonCode,
  commonCodesGetStandardInfo,
  commonCodesGetComName,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import { validateFormRefs } from "@/utils/formUtil";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "EventPriceConfigInputPopup",
  props: ["defaultMemberGrade", "startTimeArr", "endTimeArr"],
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputDate,
    InputDateRange,
    InputText,
    ErpButton,
  },
  created() {
    this.inputOptions.reptitCycleTypeOptions = commonCodesGetCommonCode(
      "REPTIT_CYCLE_TYPE"
    );
    this.inputOptions.memberGradeOptions = commonCodesGetCommonCode(
      "MEMBER_GRADE"
    );
    this.inputOptions.paymtCodeOptions = commonCodesGetCommonCode("PAYMT_CODE");
    this.inputOptions.startTimeOptions = JSON.parse(
      JSON.stringify(this.startTimeArr)
    );
    this.inputOptions.endTimeOptions = JSON.parse(
      JSON.stringify(this.endTimeArr)
    );

    console.log(this.defaultMemberGrade);

    this.tsPaymtEvent.memberGrades.push(this.defaultMemberGrade);
  },
  data() {
    return {
      inputDateRangeValue: { to: null, from: null },
      commonCodeFields: { text: "comName", value: "comCode" },
      inputOptions: {
        reptitCycleTypeOptions: null,
        memberGradeOptions: null,
        paymtCodeOptions: null,
        startTimeOptions: null,
        endTimeOptions: null,
      },
      tsPaymtEvent: {
        eventId: null,
        title: null,
        startTime: commonCodesGetStandardInfo("teeoffStartTime"),
        endTime: commonCodesGetStandardInfo("teeoffEndTime"),
        reptitCycleType: "NEVER",
        reptitDwAll: false,
        reptitDws: [false, false, false, false, false, false, false],
        reptitCycleEndDate: null,
        memberGrade: null,
        memberGrades: [],
        weekdayPaymtCode: null,
        weekendPaymtCode: null,
        remarks: null,
        insertName: null,
        insertDt: null,
        updateName: null,
        updateDt: null,
        reptitCycleEndDateDwName: null,
      },
      tsPaymtEventValidationRules: {
        title: {
          required: true,
        },
        startTime: {
          required: true,
        },
        endTime: {
          required: true,
        },
        reptitCycleType: {
          required: true,
        },
        memberGrade: {
          required: false,
        },
        memberGrades: {
          required: false,
        },
        weekdayPaymtCode: {
          required: true,
        },
        weekendPaymtCode: {
          required: true,
        },
      },
    };
  },
  methods: {
    commonCodesGetColorValue,
    validateFormRefs,
    async showPopup(args) {
      if (args) {
        if (args.eventId) {
          const data = await GolfErpAPI.getPaymentEvent(args.eventId);

          if (!data.reptitCycleEndDate) {
            data.reptitCycleEndDate = null;
          }
          if (!data.reptitDw) {
            data.reptitDw = null;
          }

          data.reptitDws = [];
          data.reptitDwAll = false;

          let reptitDwCount = 0;

          for (let i = 1; i <= 7; i++) {
            if (data.reptitDw) {
              if (data.reptitDw.indexOf(i.toString()) !== -1) {
                data.reptitDws.push(true);
                reptitDwCount++;
              } else {
                data.reptitDws.push(false);
              }
            } else {
              data.reptitDws.push(false);
            }
          }

          if (reptitDwCount === 7) {
            data.reptitDwAll = true;
          }

          this.inputDateRangeValue.from = data.startDate;
          this.inputDateRangeValue.to = data.endDate;

          data.reptitCycleEndDateDwName = data.reptitCycleEndDate
            ? "(" + getDayOfWeekCaption(data.reptitCycleEndDate) + ")"
            : "";

          this.tsPaymtEvent = data;

          if (
            this.startTimeArr.findIndex(
              (timeData) => timeData === data.startTime
            ) < 0
          ) {
            this.inputOptions.startTimeOptions.push(data.endTime);
          }
          if (
            this.endTimeArr.findIndex((timeData) => timeData === data.endTime) <
            0
          ) {
            this.inputOptions.endTimeOptions.push(data.endTime);
          }
        } else {
          this.inputDateRangeValue.from = args.startDate;
          this.inputDateRangeValue.to = args.endDate;
          this.tsPaymtEvent.startTime = args.startTime;
          this.tsPaymtEvent.endTime = args.endTime;
        }
      } else {
        const todayDate = await getTodayNavigationDate("YYYY-MM-DD");

        this.inputDateRangeValue.from = todayDate;
        this.inputDateRangeValue.to = todayDate;
      }
    },
    closeEventPriceConfigInputPopup() {
      this.$refs.eventPriceConfigInputPopup.hide();
    },
    onEventPriceConfigInputPopupClosed() {
      this.$emit("popupClosed");
    },
    onStartTimeChange(args) {
      if (args) {
        this.tsPaymtEvent.startTime = getFormattedDateTimePicker(args, "HH:mm");
      } else {
        this.tsPaymtEvent.startTime = null;
      }
    },
    onEndTimeChange(args) {
      if (args) {
        this.tsPaymtEvent.endTime = getFormattedDateTimePicker(args, "HH:mm");
      } else {
        this.tsPaymtEvent.endTime = null;
      }
    },
    onReptitCycleEndDateChanged(args) {
      this.tsPaymtEvent.reptitCycleEndDateDwName =
        "(" + commonCodesGetComName("DW_CODE", args.dwCode) + ")";
    },
    async onSaveButtonClicked() {
      this.tsPaymtEvent.startDate = this.inputDateRangeValue.from;
      this.tsPaymtEvent.endDate = this.inputDateRangeValue.to;

      if (this.tsPaymtEvent.eventId) {
        this.tsPaymtEventValidationRules.memberGrade.required = true;
        this.tsPaymtEventValidationRules.memberGrades.required = false;
      } else {
        this.tsPaymtEventValidationRules.memberGrade.required = false;
        this.tsPaymtEventValidationRules.memberGrades.required = true;
      }
      if (!this.validateFormRefs(this.tsPaymtEventValidationRules)) {
        return;
      }

      let divCntEle = null;

      if (!this.tsPaymtEvent.startDate || !this.tsPaymtEvent.endDate) {
        this.errorToast("시작/종료일자를 입력해 주십시오");
        divCntEle = this.$refs.inputDateRangeValue.$el.closest(
          "li > div.content"
        );
        divCntEle.style.boxShadow = "0 0 0 1px #f00 inset";
        return;
      } else {
        divCntEle = this.$refs.inputDateRangeValue.$el.closest(
          "li > div.content"
        );
        divCntEle.style.boxShadow = "none";
      }

      if (this.tsPaymtEvent.startDate > this.tsPaymtEvent.endDate) {
        this.errorToast("시작일자보다 종료일자가 먼저일 수 없습니다.");
        divCntEle = this.$refs.inputDateRangeValue.$el.closest(
          "li > div.content"
        );
        divCntEle.style.boxShadow = "0 0 0 1px #f00 inset";
        return;
      } else {
        divCntEle = this.$refs.inputDateRangeValue.$el.closest(
          "li > div.content"
        );
        divCntEle.style.boxShadow = "none";
      }

      if (this.tsPaymtEvent.reptitCycleType === "WEEKLY") {
        const reptitDwsFilterData = this.tsPaymtEvent.reptitDws.filter(
          (data) => data
        );

        if (!(reptitDwsFilterData.length > 0)) {
          this.errorToast("반복주기요일을 체크해 주십시오");
          return;
        }
      }

      let reptitDw = "";

      for (let i = 0; i < 7; i++) {
        if (this.tsPaymtEvent.reptitDws[i]) {
          const dwCode = (i + 1).toString();
          reptitDw += reptitDw.length > 0 ? "," + dwCode : dwCode;
        }
      }

      this.tsPaymtEvent.reptitDw = reptitDw ? reptitDw : null;

      await GolfErpAPI.patchEventPayment(this.tsPaymtEvent);

      this.$refs.eventPriceConfigInputPopup.hide();
      this.$emit("saveConfirmClosed");
    },
    async onDeleteButtonClicked() {
      if (!(await this.confirm("삭제하시겠습니까?"))) {
        return;
      }

      if (!this.tsPaymtEvent.eventId) {
        this.errorToast("이벤트 요금 정보가 존재하지 않습니다.");
        return;
      }

      this.tsPaymtEvent.delFlag = true;

      await GolfErpAPI.patchEventPayment(this.tsPaymtEvent);

      this.$refs.eventPriceConfigInputPopup.hide();
      this.$emit("saveConfirmClosed");
    },
    onReptitDwAllChanged() {
      if (this.tsPaymtEvent.reptitDwAll) {
        this.tsPaymtEvent.reptitDws[0] = true;
        this.tsPaymtEvent.reptitDws[1] = true;
        this.tsPaymtEvent.reptitDws[2] = true;
        this.tsPaymtEvent.reptitDws[3] = true;
        this.tsPaymtEvent.reptitDws[4] = true;
        this.tsPaymtEvent.reptitDws[5] = true;
        this.tsPaymtEvent.reptitDws[6] = true;
      } else {
        this.tsPaymtEvent.reptitDws[0] = false;
        this.tsPaymtEvent.reptitDws[1] = false;
        this.tsPaymtEvent.reptitDws[2] = false;
        this.tsPaymtEvent.reptitDws[3] = false;
        this.tsPaymtEvent.reptitDws[4] = false;
        this.tsPaymtEvent.reptitDws[5] = false;
        this.tsPaymtEvent.reptitDws[6] = false;
      }
    },
    onReptitDwChanged() {
      const falseDwFlags = this.tsPaymtEvent.reptitDws.filter((data) => !data);

      if (falseDwFlags.length > 0) {
        this.tsPaymtEvent.reptitDwAll = false;
      } else {
        this.tsPaymtEvent.reptitDwAll = true;
      }
    },
    onWeekdayPaymtCodeChanged() {
      if (!this.tsPaymtEvent.weekendPaymtCode) {
        this.tsPaymtEvent.weekendPaymtCode = this.tsPaymtEvent.weekdayPaymtCode;
      }
    },
    onWeekendPaymtCodeChanged() {
      if (!this.tsPaymtEvent.weekdayPaymtCode) {
        this.tsPaymtEvent.weekdayPaymtCode = this.tsPaymtEvent.weekendPaymtCode;
      }
    },
    onReptitCycleTypeChanged() {
      if (this.tsPaymtEvent.reptitCycleType === "NEVER") {
        this.tsPaymtEvent.reptitDwAll = false;
        this.tsPaymtEvent.reptitDws[0] = false;
        this.tsPaymtEvent.reptitDws[1] = false;
        this.tsPaymtEvent.reptitDws[2] = false;
        this.tsPaymtEvent.reptitDws[3] = false;
        this.tsPaymtEvent.reptitDws[4] = false;
        this.tsPaymtEvent.reptitDws[5] = false;
        this.tsPaymtEvent.reptitDws[6] = false;
      }
    },
  },
};
</script>
