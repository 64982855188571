<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  v-model="eventPriceConfigOptions.bsnDate"
                  type="lookup-condition"
                  :format="'YYYY-MM-DD'"
                  :notClear="true"
                  :isGetDateInfo="true"
                  @change="onBsnDateChanged"
                />
              </li>
              <li class="item">
                <erp-button
                    :is-icon-custom="true"
                    @click.native="onPrevDateViewButtonClicked"
                >
                  &lt;
                </erp-button>
                <erp-button
                    :is-icon-custom="true"
                    @click.native="onNextDateViewButtonClicked"
                >
                  &gt;
                </erp-button>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회원등급</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="visitSearchCourseCode"
                  v-bind="memberGradeDropDownListProps"
                  v-model="eventPriceConfigOptions.memberGrade"
                  @change="onMemberGradeChanged"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="eventPriceConfigOptions.contentViewDiv"
                        :value="'PAYMT'"
                        @change="onContentViewDivChanged"
                      />
                      <i></i>
                      <div class="label">금액</div>
                    </label>
                  </li>
                </ul>
              </li>
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        v-model="eventPriceConfigOptions.contentViewDiv"
                        :value="'TITLE'"
                        @change="onContentViewDivChanged"
                      />
                      <i></i>
                      <div class="label">이벤트 제목</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">시간간격</div>
            <ul class="content">
              <li class="item" style="width: 80px">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  ref="timeInterval"
                  v-bind="timeIntervalDropDownListProps"
                  v-model="eventPriceConfigOptions.timeInterval"
                  @change="onTimeIntervalChanged"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="searchButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="eventPriceConfig.shortcuts.addEventPrice"
                      :shortcut="{key: 'F3'}"
                      :ignore="isPopupOpened"
                      @click.native="addButtonClicked"
                  >
                    추가
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                :showCloseButton="false"
                heightAdjustMode="Auto"
                :selected="onModeDivTabSelected"
                overflowMode="Popup"
                :selectedItem="
                  eventPriceConfigOptions.modeDiv === 'MODIFY' ? 0 : 1
                "
              >
                <e-tabitems>
                  <e-tabitem :header="{ text: '편집모드' }"></e-tabitem>
                  <e-tabitem :header="{ text: '뷰모드' }"></e-tabitem>
                </e-tabitems>
              </ejs-tab>
            </div>
          </div>
          <div class="section-body">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-0101">
                <div class="section-body">
                  <ejs-grid-wrapper
                    ref="grid"
                    :columns="gridColumns"
                    :dataSource="gridData"
                    :provides="provides"
                    :selectionSettings="selectionOptions"
                    :isNOColumnDisplay="false"
                    :allowResizing="false"
                    :allowSorting="false"
                    :allowFiltering="false"
                    :rowHeight="27"
                    @queryCellInfo="gridQueryCellInfo"
                    @headerCellInfo="gridHeaderCellInfo"
                    @recordClick="gridRecordClick"
                    @cellSelected="gridCellSelected"
                    @cellSelecting="gridCellSelecting"
                  />
                </div>
              </section>
            </article>
          </div>
        </section>
      </article>
    </div>
    <event-price-config-input-popup
      v-if="isEventPriceConfigInputPopup"
      ref="eventPriceConfigInputPopup"
      :defaultMemberGrade="eventPriceConfigOptions.memberGrade"
      :startTimeArr="timeArr"
      :endTimeArr="timeEndArr"
      @popupClosed="eventPriceConfigInputPopupClose"
      @saveConfirmClosed="eventPriceConfigInputPopupSaveConfirmClosed"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputDate from "@/components/common/datetime/InputDate";
import EventPriceConfigInputPopup from "@/views/code-management/popup/EventPriceConfigInputPopup";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { ForeignKey } from "@syncfusion/ej2-vue-grids";
import { getTodayNavigationDate, getDayOfWeekCaption } from "@/utils/date";
import {
  commonCodesGetCommonCode,
  commonCodesGetStandardInfo,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import moment from "moment";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "EventPriceConfig",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    InputDate,
    EventPriceConfigInputPopup,
    ErpButton,
  },
  async created() {
    this.eventPriceConfigOptions.bsnDate = await getTodayNavigationDate("YYYY-MM-DD");
  },
  data() {
    return {
      provides: [ForeignKey],
      gridData: [],
      gridColumns: [],
      weekday: [],
      timeArr: [],
      timeEndArr: [],
      eventPriceConfigOptions: {
        bsnDate: null,
        addDay: 29,
        memberGrade: "ORANGE",
        modeDiv: "MODIFY",
        contentViewDiv: "PAYMT",
        timeInterval: 30,
      },
      isEventPriceConfigInputPopup: false,
      gridCurrentCell: null,
    };
  },
  computed: {
    isPopupOpened() {
      return this.isEventPriceConfigInputPopup;
    },
    selectionOptions() {
      let selectionSettings = {};

      if (this.eventPriceConfigOptions.modeDiv === "VIEW") {
        selectionSettings = {
          cellSelectionMode: "BoxWithBorder",
          type: "Single",
          mode: "Cell",
        };
      } else {
        selectionSettings = {
          cellSelectionMode: "BoxWithBorder",
          type: "Multiple",
          mode: "Cell",
        };
      }

      return selectionSettings;
    },
    memberGradeDropDownListProps() {
      const dataSource = JSON.parse(
          JSON.stringify(commonCodesGetCommonCode("MEMBER_GRADE", true))
      );

      return {
        allowFiltering: false,
        dataSource,
        fields: { text: "comName", value: "comCode" },
      };
    },
    timeIntervalDropDownListProps() {
      const dataSource = [10, 30, 60];

      return {
        allowFiltering: false,
        dataSource,
      };
    },
  },
  methods: {
    async onModeDivTabSelected(args) {
      if (args.selectedIndex === 0) {
        this.eventPriceConfigOptions.modeDiv = "MODIFY";
      } else {
        this.eventPriceConfigOptions.modeDiv = "VIEW";
      }
      await this.searchButtonClicked();
    },
    async onBsnDateChanged() {
      await this.searchButtonClicked();
    },
    async onMemberGradeChanged() {
      await this.searchButtonClicked();
    },
    async onTimeIntervalChanged() {
      await this.searchButtonClicked();
    },
    async searchButtonClicked() {
      if (
        this.commonMixinIsButtonDisableByAuth("codeMngGolfEventPriceConfigReg")
      ) {
        return;
      }

      this.timeArr = [];
      this.timeEndArr = [];

      const teeoffStartTime = commonCodesGetStandardInfo("teeoffStartTime");
      const teeoffEndTime = commonCodesGetStandardInfo("teeoffEndTime");

      let timeMomentObj = moment()
        .set("hour", teeoffStartTime.substring(0, 2))
        .set("minute", teeoffStartTime.substring(3, 5));

      this.timeArr.push(
        (timeMomentObj.hour() < 10
          ? "0" + timeMomentObj.hour().toString()
          : timeMomentObj.hour().toString()) +
          ":" +
          (timeMomentObj.minute() < 10
            ? "0" + timeMomentObj.minute().toString()
            : timeMomentObj.minute().toString())
      );

      while (
        parseInt(timeMomentObj.format("HHmm")) <
        parseInt(teeoffEndTime.substring(0, 2) + teeoffEndTime.substring(3, 5))
      ) {
        timeMomentObj.add(this.eventPriceConfigOptions.timeInterval, "m");

        if (
          (timeMomentObj.hour() < 10
            ? "0" + timeMomentObj.hour().toString()
            : timeMomentObj.hour().toString()) +
            (timeMomentObj.minute() < 10
              ? "0" + timeMomentObj.minute().toString()
              : timeMomentObj.minute().toString()) <=
          teeoffEndTime.substring(0, 2) + teeoffEndTime.substring(3, 5)
        ) {
          this.timeArr.push(
            (timeMomentObj.hour() < 10
              ? "0" + timeMomentObj.hour().toString()
              : timeMomentObj.hour().toString()) +
              ":" +
              (timeMomentObj.minute() < 10
                ? "0" + timeMomentObj.minute().toString()
                : timeMomentObj.minute().toString())
          );
        }
      }

      this.weekday = [];

      let dateMomentObj = moment(this.eventPriceConfigOptions.bsnDate);

      const startDate = dateMomentObj.format("YYYY-MM-DD");
      const endDate = dateMomentObj
        .add(this.eventPriceConfigOptions.addDay, "d")
        .format("YYYY-MM-DD");
      const calenderInfos = await GolfErpAPI.fetchCalenderInfos(
        startDate,
        endDate
      );

      calenderInfos.forEach((calenderInfo) => {
        this.weekday.push({
          bsnDate: calenderInfo.bsnDate,
          bsnCode: calenderInfo.bsnCode,
          dwCode: calenderInfo.dwCode,
        });
      });

      const paymentEventsSchedule = await GolfErpAPI.getPaymentEventsSchedule(
        this.eventPriceConfigOptions
      );

      let data = [];

      this.timeArr.forEach((timeData) => {
        const filterData = paymentEventsSchedule.filter(
          (data) => data.resveTime === timeData
        );

        const pushData = {
          resveTime: timeData,
          eventPriceData: filterData,
        };

        data.push(pushData);
        this.timeEndArr.push(
          moment()
            .set("hour", timeData.substring(0, 2))
            .set("minute", timeData.substring(3, 5))
            .add(this.eventPriceConfigOptions.timeInterval - 1, "m")
            .format("HH:mm")
        );
      });

      this.setGridData(data);
    },
    gridQueryCellInfo(args) {
      if (args.column.field.substring(0, 7) === "bsnDate") {
        const data = args.data;
        const field = args.column.field;

        if (
          this.eventPriceConfigOptions.modeDiv === "VIEW" &&
          args.column.isRowSpanFlag
        ) {
          args.rowSpan = data[field + "TimeCount"]
            ? data[field + "TimeCount"]
            : 1;
        }

        if (args.column.isBoxLeftBorderFlag) {
          args.cell.style.borderLeft = "1px solid #000000";
        }
        if (this.eventPriceConfigOptions.contentViewDiv === "PAYMT") {
          if (data[field + "BsnCode"] === "CLOSE") {
            args.cell.innerText = data[field] ? data[field] : "";
            args.cell.style.textAlign = "left";
          } else {
            args.cell.innerText =
              !!data[field + "PaymtPrice"] || data[field + "PaymtPrice"] === 0
                ? numberWithCommas(data[field + "PaymtPrice"])
                : "";
            args.cell.style.textAlign = "right";
          }
        } else {
          args.cell.innerText = data[field] ? data[field] : "";
          args.cell.style.textAlign = "left";
        }
      }
    },
    gridHeaderCellInfo(args) {
      if (args.cell.index === 1) {
        args.cell.isSpanned = true;
      }

      if (args.cell.column.field.substring(0, 7) === "bsnDate") {
        const calenderInfo = this.weekday[args.cell.colIndex - 1];

        args.node.innerText =
          args.cell.column.headerText.substring(5, 10) +
          " (" +
          getDayOfWeekCaption(args.cell.column.headerText) +
          ")";
        args.node.style.textAlign = "center";
        args.node.style.color =
          calenderInfo.dwCode === "7"
            ? commonCodesGetColorValue("DW_CODE", calenderInfo.dwCode)
            : commonCodesGetColorValue("BSN_CODE", calenderInfo.bsnCode);
        args.node.style.fontWeight = "bold";

        if (
          args.node.innerText.substring(0, 5) === "01-01" &&
          args.cell.column.index > 1
        ) {
          args.node.style.borderLeft = "1px solid #000000";
        }
      } else if (args.cell.column.field === "resveTime") {
        args.node.innerText = "시간";
        args.node.style.textAlign = "center";
        args.node.style.color = "#000000";
        args.node.style.fontWeight = "bold";
      }
    },
    gridRecordClick(args) {
      const field = args.column.field;
      const data = {
        eventId: args.rowData[field + "EventId"],
        startDate: args.rowData[field + "BsnDate"],
        endDate: args.rowData[field + "BsnDate"],
        startTime: args.rowData.resveTime,
        endTime:
          args.rowData.resveTime.substring(0, 2) +
          ":" +
          (this.eventPriceConfigOptions.timeInterval === 30
            ? args.rowData.resveTime.substring(3, 5) === "30"
              ? "59"
              : "29"
            : this.eventPriceConfigOptions.timeInterval === 60
            ? "59"
            : this.eventPriceConfigOptions.timeInterval === 10
            ? args.rowData.resveTime.substring(3, 4) + "9"
            : args.rowData.resveTime.substring(3, 5)),
      };

      this.isEventPriceConfigInputPopup = true;
      this.$nextTick(() => {
        this.$refs.eventPriceConfigInputPopup.showPopup(data);
      });
    },
    setGridData(data) {
      let columnsData = [
        {
          field: "resveTime",
          headerText: "시간",
          textAlign: "center",
          isPrimaryKey: true,
          width: 80,
        },
      ];

      this.weekday.forEach((data, idx) => {
        const gridColumn = {
          field: "bsnDate" + idx.toString(),
          headerText: data.bsnDate,
          width: 100,
        };

        columnsData.push(gridColumn);
      });

      const weekDayLength = this.weekday.length;

      for (let i = 0; i < weekDayLength; i++) {
        let dwName = "bsnDate" + i.toString();

        data.forEach((eventData) => {
          const eventPriceData = eventData.eventPriceData;

          eventData[dwName + "BsnDate"] = this.weekday[i].bsnDate;
          eventData[dwName + "BsnCode"] = this.weekday[i].bsnCode;
          eventData[dwName + "DwCode"] = this.weekday[i].dwCode;

          if (!!eventPriceData && eventPriceData.length > 0) {
            const filterEventData = eventPriceData.filter(
              (data) => data.bsnDate === this.weekday[i].bsnDate
            );

            if (filterEventData.length > 0) {
              const filterEventDataLength = filterEventData.length;

              for (let a = 0; a < filterEventDataLength; a++) {
                eventData[dwName] = filterEventData[a].title;
                eventData[dwName + "EventId"] = filterEventData[a].eventId;
                eventData[dwName + "TimeCount"] =
                  filterEventData[a].eventTimeCount;
                eventData[dwName + "PaymtPrice"] =
                  filterEventData[a].paymtPrice;

                columnsData[i + 1].isRowSpanFlag = true;
              }
            }
          }
        });

        if (i > 0) {
          if (
            this.weekday[i].bsnDate.substring(0, 4) !==
            this.weekday[i - 1].bsnDate.substring(0, 4)
          ) {
            columnsData[i + 1].isBoxLeftBorderFlag = true;
          } else {
            columnsData[i + 1].isBoxLeftBorderFlag = false;
          }
        }
      }

      this.gridData = data;
      this.gridColumns = columnsData;
    },
    eventPriceConfigInputPopupClose() {
      this.isEventPriceConfigInputPopup = false;
    },
    async eventPriceConfigInputPopupSaveConfirmClosed() {
      this.isEventPriceConfigInputPopup = false;

      await this.searchButtonClicked();
    },
    addButtonClicked() {
      const selectedRowCellIndexes = this.$refs.grid.getSelectedRowCellIndexes();

      if (!(selectedRowCellIndexes.length > 0)) {
        this.isEventPriceConfigInputPopup = true;
        this.$nextTick(() => {
          this.$refs.eventPriceConfigInputPopup.showPopup();
        });
        return;
      }

      const selectedMatrix = [];
      selectedRowCellIndexes.forEach(({ rowIndex, cellIndexes }) => {
        cellIndexes.forEach((cellIndex) => {
          cellIndex >= 1 &&
            selectedMatrix.push({ row: rowIndex, col: cellIndex });
        });
      });

      const currentViewRecords = this.$refs.grid.getCurrentViewRecords();

      const selectedCellData = selectedMatrix.map(({ row, col }) => {
        const rowData = currentViewRecords[row];
        const column = this.gridColumns[col];

        const bsnDate = rowData[column.field + "BsnDate"];
        const resveTime = rowData.resveTime;
        const eventId = rowData[column.field + "EventId"];

        return {
          bsnDate,
          resveTime,
          eventId,
        };
      });

      const existingEventIdData = selectedCellData.filter(
        (data) => !!data.eventId
      );

      if (existingEventIdData.length > 0) {
        this.errorToast("이미 등록된 이벤트가 포함되어 있습니다.");
        return;
      }

      const minDateMomentObject = Math.min.apply(
        Math,
        selectedCellData.map((a) => {
          return moment(a.bsnDate);
        })
      );

      const startDate = moment(minDateMomentObject).format("YYYY-MM-DD");

      const maxDateMomentObject = Math.max.apply(
        Math,
        selectedCellData.map((a) => {
          return moment(a.bsnDate);
        })
      );

      const endDate = moment(maxDateMomentObject).format("YYYY-MM-DD");

      const minTimeMomentObject = Math.min.apply(
        Math,
        selectedCellData.map((a) => {
          return moment()
            .set("hour", a.resveTime.substring(0, 2))
            .set("minute", a.resveTime.substring(3, 5));
        })
      );

      const startTime = moment(minTimeMomentObject).format("HH:mm");

      const maxTimeMomentObject = Math.max.apply(
        Math,
        selectedCellData.map((a) => {
          return moment()
            .set("hour", a.resveTime.substring(0, 2))
            .set("minute", a.resveTime.substring(3, 5));
        })
      );

      let endTime = moment(maxTimeMomentObject).format("HH:mm");

      endTime =
        endTime.substring(0, 3) +
        (this.eventPriceConfigOptions.timeInterval === 30
          ? moment(maxTimeMomentObject).format("mm") === "30"
            ? "59"
            : "29"
          : this.eventPriceConfigOptions.timeInterval === 60
          ? "59"
          : this.eventPriceConfigOptions.timeInterval === 10
          ? moment(maxTimeMomentObject).format("mm").substring(0, 1) + "9"
          : moment(maxTimeMomentObject).format("mm"));

      const data = {
        eventId: null,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        memberGrade: this.eventPriceConfigOptions.memberGrade,
      };

      this.isEventPriceConfigInputPopup = true;
      this.$nextTick(() => {
        this.$refs.eventPriceConfigInputPopup.showPopup(data);
      });
    },
    gridCellSelecting(args) {
      const rowCellIndex = args.cellIndex;
      const previousRowCellIndex = args.previousRowCellIndex;
      const cellIndex = rowCellIndex.cellIndex;

      if (!previousRowCellIndex || cellIndex === 0) {
        return;
      }

      let rowIndex = null;
      let preRowIndex = null;

      let startTime = null;
      let endTime = null;

      if (previousRowCellIndex) {
        rowIndex = rowCellIndex.rowIndex;
        preRowIndex = previousRowCellIndex.rowIndex;
      } else {
        rowIndex = rowCellIndex.rowIndex;
        preRowIndex = rowCellIndex.rowIndex;
      }

      if (rowIndex === preRowIndex) {
        startTime = this.timeArr[rowIndex];
        endTime =
          this.timeArr[rowIndex].substring(0, 2) +
          ":" +
          (this.eventPriceConfigOptions.timeInterval === 30
            ? this.timeArr[rowIndex].substring(3, 5) === "30"
              ? "59"
              : "29"
            : this.eventPriceConfigOptions.timeInterval === 60
            ? "59"
            : this.eventPriceConfigOptions.timeInterval === 10
            ? this.timeArr[rowIndex].substring(3, 4) + "9"
            : this.timeArr[rowIndex].substring(3, 5));
      } else {
        if (rowIndex < preRowIndex) {
          startTime = this.timeArr[rowIndex];
          endTime =
            this.timeArr[preRowIndex].substring(0, 2) +
            ":" +
            (this.eventPriceConfigOptions.timeInterval === 30
              ? this.timeArr[preRowIndex].substring(3, 5) === "30"
                ? "59"
                : "29"
              : this.eventPriceConfigOptions.timeInterval === 60
              ? "59"
              : this.eventPriceConfigOptions.timeInterval === 10
              ? this.timeArr[preRowIndex].substring(3, 4) + "9"
              : this.timeArr[preRowIndex].substring(3, 5));
        } else {
          startTime = this.timeArr[preRowIndex];
          endTime =
            this.timeArr[rowIndex].substring(0, 2) +
            ":" +
            (this.eventPriceConfigOptions.timeInterval === 30
              ? this.timeArr[rowIndex].substring(3, 5) === "30"
                ? "59"
                : "29"
              : this.eventPriceConfigOptions.timeInterval === 60
              ? "59"
              : this.eventPriceConfigOptions.timeInterval === 10
              ? this.timeArr[rowIndex].substring(3, 4) + "9"
              : this.timeArr[rowIndex].substring(3, 5));
        }
      }

      if (args.data["bsnDate" + (cellIndex - 1) + "EventId"]) {
        return;
      }

      args.currentCell.innerText = startTime + "~" + endTime;
      args.currentCell.style.color = "#cccccc";

      this.gridCurrentCell = args.currentCell;
    },
    gridCellSelected(args) {
      if (this.gridCurrentCell) {
        this.gridCurrentCell.innerText = "";
        this.gridCurrentCell.style.color = "";
      }

      if (
        (args.selectedRowCellIndex.length > 0 &&
          args.selectedRowCellIndex[0].cellIndexes.length > 1) ||
        args.selectedRowCellIndex.length > 1
      ) {
        this.addButtonClicked();
      }
    },
    onPrevDateViewButtonClicked() {
      const dateMomentObj = moment(this.eventPriceConfigOptions.bsnDate);

      const prevDate = dateMomentObj.subtract(14, "d").format("YYYY-MM-DD");

      this.eventPriceConfigOptions.bsnDate = prevDate;

      this.searchButtonClicked();
    },
    onNextDateViewButtonClicked() {
      const dateMomentObj = moment(this.eventPriceConfigOptions.bsnDate);

      this.eventPriceConfigOptions.bsnDate = dateMomentObj.add(14, "d").format("YYYY-MM-DD");

      this.searchButtonClicked();
    },
    onContentViewDivChanged() {
      this.$refs.grid.refresh();
    },
  },
};
</script>
